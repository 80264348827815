module.exports = {
  messages: {
    CN: {
			discountCode: '专属折扣码',
			validityPeriod: '有效期',
			discount: '折扣',
			totalUses: '总次数',
			remainingUses: '剩余次数',
			commissionManagement: '返佣管理',
			id: 'ID',
			subsDate: '订阅日期',
			commissionDate: '返佣日期',
			fullyRefunded: '暂停返佣，已返还全部',
			userRefunded: '暂停返佣，用户已退款',
			subscriptionPlan: '订阅版本',
			billingCycle: '订阅周期',
			monthlyPayment: '按月支付',
			annualPayment: '按年支付',
			originalPrice: '原价',
			discountedPrice: '优惠价格',
			finalPaidAmount: '实付价格',
			commissionEligible: '是否返佣',
			yes: '是',
			no: '无',
			expectedCommission: '应返佣金',
			paidCommission: '已返佣金',
			pendingCommission: '待返佣金',
			tipTitle1: '折扣码是否有使用次数？',
			tipContent1: '是的，每个折扣码都有使用次数的限制，您可以在返佣管理列表折扣码基础信息处中查看剩余的次数。',
			tipTitle2: '折扣码是否有有效期？',
			tipContent2: '是的，折扣码是有有效期的，您可以在返佣管理列表折扣码基础信息处查看有效期。',
			tipTitle3: '折扣码抵扣规则',
			tipContent3: '折扣码仅可用于首次支付时抵扣，后期续费时不再享受折扣码的折扣。',
			tipTitle4: '返佣规则',
			tipContent4: '1.用户通过您的折扣码完成支付后，会进入到您的返佣列表。',
			tipContent5: '2.用户选择按月订阅，则佣金会在用户支付满30天后进行一次性发放，若30天内用户退款，则佣金不会发放。',
			tipContent6: '3.用户选择按年订阅，则会在用户支付满30天后进行第一次发放，后续每满30天进行一次发放，直至所有的佣金发放完毕，若中间用户退款，则后续佣金不再发放。',
			tipFooterText: '以上解释权归strategybrain所有。',
			noCamp: '暂无内容',
			discountTxt: '折'
    },

    US: {
			discountCode: 'Exclusive Discount Code',
			validityPeriod: 'Validity Period',
			discount: 'Discount',
			totalUses: 'Total Uses',
			remainingUses: 'Remaining Uses',
			commissionManagement: 'Commission Management',
			id: 'ID',
			subsDate: 'Subscription Date',
			commissionDate: 'Commission Date',
			fullyRefunded: 'Commission Paused, Fully Refunded',
			userRefunded: 'Commission Paused, User Refunded',
			subscriptionPlan: 'Subscription Plan',
			billingCycle: 'Billing Cycle',
			monthlyPayment: 'Monthly Payment',
			annualPayment: 'Annual Payment',
			originalPrice: 'Original Price',
			discountedPrice: 'Discounted Price',
			finalPaidAmount: 'Final Paid Amount',
			commissionEligible: 'Commission Eligible',
			yes: 'Yes',
			no: 'No',
			expectedCommission: 'Expected Commission',
			paidCommission: 'Paid Commission',
			pendingCommission: 'Pending Commission',
			tipTitle1: 'Is there a usage limit for discount codes?',
			tipContent1: 'Yes, each discount code has a usage limit. You can view it under the basic info in the Commission Management list.',
			tipTitle2: 'Do discount codes have an end date?',
			tipContent2: 'Yes, discount codes expire. You can check the validity in the Basic Info section of the Commission Management list.',
			tipTitle3: 'Discount Code Deduction Policy',
			tipContent3: 'You can only apply discount codes to the first payment, and they do not apply to future renewals.',
			tipTitle4: 'Commission Policy',
			tipContent4: '1. When a user completes a payment using your discount code, we will add them to your commission list.',
			tipContent5: '2. For monthly subscribers, we will pay the commission in a single payout after 30 days of payment. If the user requests a refund within 30 days, we will not release the commission.',
			tipContent6: '3. For annual subscribers, the first payout will be after 30 days. We will make later payouts every 30 days until we pay out the total commission. If the user requests a refund at any point, we will cancel the remaining payouts.',
			tipFooterText: 'StrategyBrain reserves all rights to interpretation.',
			noCamp: 'No content available',
			discountTxt: 'Discount'
    },
    AR: {
			discountCode: 'رمز خصم حصري',
			validityPeriod: 'فترة الصلاحية',
			discount: 'الخصم',
			totalUses: 'إجمالي عدد مرات الاستخدام',
			remainingUses: 'عدد مرات الاستخدام المتبقية',
			commissionManagement: 'إدارة العمولات',
			id: 'معرف الحساب',
			subsDate: 'تاريخ الاشتراك',
			commissionDate: 'تاريخ العمولة',
			fullyRefunded: 'تم إيقاف العمولة، وتم استرداد المبلغ بالكامل',
			userRefunded: 'تم إيقاف العمولة، وتم استرداد المبلغ للمستخدم',
			subscriptionPlan: 'خطة الاشتراك',
			billingCycle: 'دورة الفوترة',
			monthlyPayment: 'الدفع الشهري',
			annualPayment: 'الدفع السنوي',
			originalPrice: 'السعر الأصلي',
			discountedPrice: 'السعر المخفض',
			finalPaidAmount: 'المبلغ المدفوع النهائي',
			commissionEligible: 'مؤهل للحصول على العمولة',
			yes: 'نعم',
			no: 'لا',
			expectedCommission: 'العمولة المتوقعة',
			paidCommission: 'العمولة المدفوعة',
			pendingCommission: 'العمولة المعلقة',
			tipTitle1: 'هل هناك حد لاستخدام رموز الخصم؟',
			tipContent1: 'نعم، لكل رمز خصم حد للاستخدام. يمكنك عرض ذلك ضمن المعلومات الأساسية في قائمة إدارة العمولات.',
			tipTitle2: 'هل لرموز الخصم تاريخ انتهاء؟',
			tipContent2: 'نعم، تنتهي صلاحية رموز الخصم. يمكنك التحقق من صلاحيتها في قسم المعلومات الأساسية في قائمة إدارة العمولات.',
			tipTitle3: 'سياسة خصم رمز الخصم',
			tipContent3: 'يمكن استخدام رموز الخصم فقط على الدفعة الأولى ولا تنطبق على التجديدات المستقبلية.',
			tipTitle4: 'سياسة العمولة',
			tipContent4: 'عند إتمام المستخدم للدفع باستخدام رمز الخصم الخاص بك، سيتم إضافته إلى قائمة العمولات الخاصة بك.',
			tipContent5: 'للمشتركين الشهريين، سيتم دفع العمولة في دفعة واحدة بعد مرور 30 يومًا من الدفع. إذا طلب المستخدم استرداد المبلغ خلال 30 يومًا، فلن يتم إصدار العمولة.',
			tipContent6: 'للمشتركين السنويين، سيتم دفع العمولة الأولى بعد 30 يومًا، وسيتم إجراء الدفعات التالية كل 30 يومًا حتى يتم دفع إجمالي العمولة. إذا طلب المستخدم استرداد المبلغ في أي وقت، سيتم إلغاء الدفعات المتبقية.',
			tipFooterText: 'تحتفظ StrategyBrain بجميع حقوق التفسير.',
			noCamp: 'لا يوجد محتوى حاليًا',
			discountTxt: 'خصم'
    },
  },
};
