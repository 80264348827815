<template>
  <div class="home">
    <div class="discount-list">
      <ul>
        <li>
          <div class="left">
            <div class="name" :class="[lang]">
              {{ $t('discountCode') }}
              <a-popover
                placement="bottomLeft"
                style="z-index: 98"
                trigger="click"
                v-model="visiable"
              >
                <template slot="content">
                  <div class="popbox">
                    <div
                      class="ptit"
                      :class="[lang]"
                      :style="{
                        'text-align': lang == 'AR' ? 'right' : 'left',
                      }"
                    >
                      <span
                        class="num"
                        :class="{ rtl: lang == 'AR' ? true : false }"
                      ></span
                      ><span :class="[lang]">{{ $t('tipTitle1') }}</span>
                    </div>
                    <div class="text" :class="[lang]">
                      {{ $t('tipContent1') }}
                    </div>
                    <div
                      class="ptit mtop"
                      :class="[lang]"
                      :style="{
                        'text-align': lang == 'AR' ? 'right' : 'left',
                      }"
                    >
                      <span
                        class="num"
                        :class="{ rtl: lang == 'AR' ? true : false }"
                      ></span
                      ><span :class="[lang]">{{ $t('tipTitle2') }}</span>
                    </div>

                    <div class="text" :class="[lang]">
                      {{ $t('tipContent2') }}
                    </div>

                    <div
                      class="ptit mtop"
                      :class="[lang]"
                      :style="{
                        'text-align': lang == 'AR' ? 'right' : 'left',
                      }"
                    >
                      <span
                        class="num"
                        :class="{ rtl: lang == 'AR' ? true : false }"
                      ></span
                      ><span :class="[lang]">{{ $t('tipTitle3') }}</span>
                    </div>
                    <div class="text" :class="[lang]">
                      {{ $t('tipContent3') }}
                    </div>

                    <div
                      class="ptit mtop"
                      :class="[lang]"
                      :style="{
                        'text-align': lang == 'AR' ? 'right' : 'left',
                      }"
                    >
                      <span
                        class="num"
                        :class="{ rtl: lang == 'AR' ? true : false }"
                      ></span
                      ><span :class="[lang]">{{ $t('tipTitle4') }}</span>
                    </div>
                    <div class="text" :class="[lang]">
                      {{ $t('tipContent4') }}
                    </div>
                    <div class="text" :class="[lang]">
                      {{ $t('tipContent5') }}
                    </div>
                    <div class="text" :class="[lang]">
                      {{ $t('tipContent6') }}
                    </div>
                    <div
											:class="[lang]"
                      :style="{
												'font-weight': '400',
                        'font-size': '14px',
                        'color': '#0e756a',
                        'line-height': '30px',
                        'margin-top': '34px',
												'text-align': lang == 'AR' ? 'right' : 'left',
											}"
                    >
                      {{ $t('tipFooterText') }}
                    </div>
                  </div>
                </template>

                <svg-icon
                  @click="visiable = true"
                  iconClass="help"
                  class="icon"
                ></svg-icon>
              </a-popover>
            </div>
            <div class="code" :class="[lang]">{{ codeData.code }}</div>
            <div class="expirseTime">
              <span
								:class="[lang]"
								:style="{
									'margin-left': lang == 'AR' ? '8px' : '0',
									'margin-right': lang == 'AR' ? '0' : '8px',
								}"
							>{{ $t('validityPeriod') }}:</span>
              <span
                class="time"
                :class="{
                  [lang]: !codeData.expiration_date ? true : false
                }"
              >{{ getCodeTime(codeData.expiration_date) }}</span>
            </div>
          </div>
          <div class="right">
            <dl>
              <dd>
                <div class="tit" :class="[lang]">{{ $t('discount')}}</div>
                <div class="numbers" :class="[lang]">
                  <span>{{ codeData.discount_rate ? (codeData.discount_rate * 10).toFixed(1) : '-' }}</span><span class="dis">{{ $t("discountTxt") }}</span>
                </div>
              </dd>
              <dd class="line">
                <div
									class="tit"
									:class="[lang]"
									:style="{
										'margin-top': lang == 'AR' ? '0' : '19px'
									}"
								>{{ $t('totalUses') }}</div>
                <div class="numbers" :class="[lang]"><span>{{ codeData.max_usage_count || 0 }}</span></div>
              </dd>
              <dd>
                <div
									class="tit"
									:class="[lang]"
									:style="{
										'margin-top': lang == 'AR' ? '0' : '19px'
									}"
								>{{ $t('remainingUses') }}</div>
                <div class="numbers" :class="[lang]"><span>{{ codeData.useful_count || 0 }}</span></div>
              </dd>
            </dl>
          </div>
        </li>
      </ul>
    </div>
    <div class="wrap-table">
      <div class="title title2">
        <div class="tit">
          <svg-icon iconClass="more2" class="icon"></svg-icon
          ><span>{{ $t('commissionManagement') }}</span>
        </div>
      </div>
      <a-table
        :scroll="{
          x: 1120,
        }"
        :locale="{ emptyText: $t('noCamp') }"
        size="middle"
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :data-source="listData"
        :pagination="false"
        :loading="loading"
        :row-class-name="
          (_record, index) => (index % 2 !== 1 ? 'table-bg' : null)
        "
      >
        <template slot="status" slot-scope="text, record" class="arial">
          <div class="statusbox" @click.stop="">
            <span v-if="record.status == '1'">{{ $t("Active") }}</span>
            <span v-if="record.status == '0'">{{ $t("Paused") }}</span>
          </div>
          <div @click.stop="">
            <a-switch
              size="small"
              v-model="record.status"
              @change="onChangeRecord(record)"
            />
          </div>
        </template>

        <template slot="action" slot-scope="text, record" class="arial">
          <a-dropdown>
            <template #overlay>
              <a-menu @click.stop="handleMenuClick.bind(record)">
                <a-menu-item key="1" @click="goDetail(record)">
                  <a-icon type="edit" />
                  {{ $t("Detail") }}
                </a-menu-item>
                <a-menu-item key="2" @click="copyHandler(record)">
                  <a-icon type="copy" />
                  {{ $t("Copy") }}
                </a-menu-item>
                <a-menu-item key="3" @click="deleteHandler(record)">
                  <a-icon type="delete" />
                  {{ $t("Delete") }}
                </a-menu-item>
              </a-menu>
            </template>
            <a-button style="padding: 0 8px" @click.stop="">
              {{ $t("Action") }}
              <a-icon type="down" />
            </a-button>
          </a-dropdown>
        </template>
      </a-table>
      <div>
        <app-footer
          :current="page"
          :pageSize="pageSize"
          :total="total"
          @onPageChange="onPageChange"
          @onPageSizeChange="onPageSizeChange"
        ></app-footer>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
export default {
  data() {
    return {
      visiable: false,
			loading: false,
			listData: [],
			codeData: {},
			page: 1,
			pageSize: 10,
			total: 0,
    };
  },
	i18n: require("./i18n"),
  computed: {
    columns: {
      get() {
        let that = this;
        return [
          {
            title: that.$t("id"),
            width: 100,
            dataIndex: "id",
            align: "center",
            fixed: "left",
            customRender: function (text, record, index) {
              return (index + 1) + (that.page - 1) * that.pageSize;
            },
          },
          {
            title: that.$t("subsDate"),
            width: 160,
            dataIndex: "create_date",
            align: "center",
            // fixed: "left",
            customRender: function (text) {
              return (text ? dayjs(text).format("YYYY-MM-DD HH:mm") : "-");
            },
          },
          {
            title: that.$t("commissionDate"),
            width: 160,
            dataIndex: "rebate_date",
            align: "center",
          },
          {
            title: that.$t("subscriptionPlan"),
            width: 120,
            dataIndex: "plan_name",
            align: "center",
          },
          {
            title: that.$t("billingCycle"),
            width: 120,
            dataIndex: "duration_type",
            align: "center",
						customRender: function (text) {
							return text == 2 ? that.$t("monthlyPayment") : that.$t("annualPayment");
						}
          },
          {
            title: that.$t("originalPrice"),
            width: 120,
            dataIndex: "original_price",
            align: "center",
						customRender: function (text) {
              return (text ? "US$" + text : "0");
            },
          },
          {
            title: that.$t("discountedPrice"),
            width: 120,
            dataIndex: "discount_price",
            align: "center",
            customRender: function (text) {
              return (text ? "US$" + text : "0");
            },
          },

          {
            title: that.$t("finalPaidAmount"),
            width: 120,
            dataIndex: "payed_price",
            align: "center",
						customRender: function (text) {
              return (text ? "US$" + text : "0");
            },
          },
          {
            title: that.$t("commissionEligible"),
            width: 120,
            dataIndex: "is_rebate",
            align: "center",
            customRender: function (text) {
              return (text == 0 || text == 3 ? that.$t("no") : that.$t("yes"));
            },
          },

          {
            title: that.$t("expectedCommission"),
            width: 120,
            dataIndex: "rebate_total",
            align: "center",
            customRender: function (text) {
              return (text ? "$" + text : "0");
            },
          },
          {
            title: that.$t("paidCommission"),
            width: 120,
            dataIndex: "rebated_amount",
            align: "center",
            customRender: function (text) {
              return (text ? "$" + text : "0");
            },
          },
          {
            title: that.$t("pendingCommission"),
            align: "center",
            // fixed: "right",
            width: 120,
            customRender: function (row) {
							let num = row.rebate_total - row.rebated_amount;
              return ( num && num >  0 ? "$" + num.toFixed(2) : "0");
            },
          },
        ];
      },
    },
  },
	activated() {
    this.getList();
  },
	methods: {
		getList () {
			// this.loading = true;
			// 获取推荐码
			this.$axios(
				'/payment/referrer_code_list',
				{},
				'post'
			).then(codeData => {
				if (codeData.code != 200) {
					this.loading = false;
					return;
				}
				let codeId = codeData.data && codeData.data[0] && codeData.data[0].id;
				this.codeData = codeData.data && codeData.data[0] || {};
        if (codeId == undefined) {
          this.loading = false;
          return;
        }
				this.getListInfo();
			}).catch(err => {
				this.loading = false;
				console.log(err)
			})
		},
    getListInfo () {
      if (this.codeData.id == undefined) {
        return;
      }
      this.loading = true;
      this.$axios(
        "/payment/referrer_detail",
        {
          discount_code_id: this.codeData.id,
          page: this.page,
          page_size: this.pageSize
        },
        "post"
      ).then((res) => {
        this.loading = false;
        let data = res.data || {}
        if (res.code == 200) {
          let list = data.results || [];
          // list.forEach((item, index) => {
          //   item.listId = index + 1;
          // })
          this.listData = list;
          this.total = data && data.total || 0;
        }
      }).catch(() => {
        this.loading = false;
      })
    },
		getCodeTime (time, type = 'YYYY-MM-DD') {
			if (!time) {
				return '--';
			}
			return dayjs(time).format(type);
		},
    onPageChange (page) {
      this.page = page;
      this.getListInfo();
    },
    onPageSizeChange (current, size) {
      this.pageSize = size;
      this.page = 1;
      this.getListInfo();
    }
	}
};
</script>
<style lang="less" scoped>
.home {
  margin: 30px 30px 30px 30px;
}
.discount-list {
  ul,
  li {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    width: 100%;
    height: 160px;
    background: url(../../assets/img/discount_bg.png) no-repeat;
    background-size: 100% 100%;
    display: flex;
		// justify-content: space-between;
    .left {
      margin-left: 200px;
      margin-top: 33px;
			width: 594px;
      .icon {
        margin-left: 6px;
      }
      .name {
        font-weight: bold;
        font-size: 18px;
        color: #775a4c;
        height: 24px;
        line-height: 24px;
      }
      .code {
        font-weight: bold;
        font-size: 36px;
        color: #4a352b;
        height: 47px;
        line-height: 47px;
      }
      .expirseTime {
        padding-top: 8px;
        font-weight: bold;
        font-size: 14px;
        color: #84571f;
				.time {
					font-size: 16px;
				}
      }
    }
    .right {
      width: 460px;
			min-width: 460px;
      height: 100px;
      // margin-left: 220px;
      margin-top: 30px;
      background: url(../../assets/img/bg1.png) no-repeat;
      border-radius: 4px 4px 4px 4px;

      dl {
        display: flex;
        align-items: center;
				height: 100px;
				margin-bottom: 0;
        dd {
          flex: 1;
          text-align: center;
          .tit {
            margin-top: 19px;
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: bold;
            font-size: 14px;
            color: #926752;
          }
          .numbers {
            margin-top: 10px;
            font-weight: bold;
            height: 33px;
            line-height: 33px;
            font-size: 30px;
            color: #684b3e;
            .dis {
              font-weight: bold;
              font-size: 12px;
              color: #684b3e;
							margin-left: 4px;
            }
          }
        }
        .line {
          position: relative;
        }
        .line::after {
          position: absolute;
          content: "";
          display: inline-block;
          width: 1px;
          height: 36px;
          background: #a57436;
          right: 1px;
          top: 30px;
        }
        .line::before {
          position: absolute;
          content: "";
          display: inline-block;
          width: 1px;
          height: 36px;
          background: #a57436;
          left: 1px;
          top: 30px;
        }
      }
    }
  }
}
.wrap-table {
  padding: 0px 20px 12px 20px;
  background: #fff;
  border-radius: 4px;
  margin-top: 20px;
  .title {
    padding: 20px 0 14px 0;

    .tit {
      display: flex;
      align-items: center;
    }
    .icon {
      color: #1c746b;
      font-size: 16px;
    }
    span {
      font-family: Arial, Arial;
      font-weight: 400;
      font-size: 18px;
      color: #000000;
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px;
    }
  }
  .title2 {
    display: flex;
    justify-content: space-between;
    .campaignsDetail {
      font-weight: 400;
      font-size: 14px;
      color: #0e756a;
      cursor: pointer;
      .ir {
        font-size: 12px;
        font-weight: bold;
      }
    }
  }
}
.popbox {
  width: 530px;
  .ptit {
    font-weight: bold;
    font-size: 16px;
    color: #1c7a70;
    display: flex;
    align-items: center;
    .num {
      width: 6px;
      height: 6px;
      background: #1c7a70;
      margin-right: 10px;
    }
		&.AR .num {
			margin-left: 10px;
			margin-right: 0;
		}
  }
  .text {
    font-weight: 400;
    font-size: 14px;
    color: #646794;
    line-height: 24px;
    margin-top: 16px;
		&.AR {
			text-align: right;
		}
  }
  .mtop {
    margin-top: 37px;
  }
  .m16 {
    margin-bottom: 16px;
  }
}
</style>
